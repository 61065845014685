import { CALL_LIST, DASHBOARD_RECORDS, CALL_DETAILS } from '../../constants/ActionTypes'

const INIT_STATE = {
  list:[],
  dashboardCounts:{},
  details:{}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CALL_LIST: {
      return {...state, list: action.payload};
    }
    case DASHBOARD_RECORDS: {
      return {...state, dashboardCounts: action.payload};
    }
    case CALL_DETAILS: {
      return {...state, details: action.payload};
    }
    default:
      return state;
  }
}
