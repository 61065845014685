import {combineReducers} from "redux";
import {routerReducer} from "react-router-redux";
import Settings from "./Settings";
import Auth from "./Auth";
import Users from './Users'
import Calls from './Calls'
import Common from "./Common";
import Roles from "./Roles";
import Twilio from "./Twilio";

const reducers = combineReducers({
  routing: routerReducer,
  settings: Settings,
  auth: Auth,
  Users: Users,
  commonData: Common,
  Calls: Calls,
  Roles: Roles,
  twilio: Twilio
});

export default reducers;
