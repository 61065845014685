import {TWILIO_NUMBERS_LIST, TWILIO_LOADER, SHOW_TWILIO_NUMBERS_LIST_MODAL} from "../../constants/ActionTypes";

const INIT_STATE = {
  twilioNumberList: [],
  loader:{
    searching: false,
  },
  showModal: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TWILIO_NUMBERS_LIST: {
      return {...state, twilioNumberList: action.payload};
    }
    case TWILIO_LOADER: {
        return {...state, loader: action.payload};
    }
    case SHOW_TWILIO_NUMBERS_LIST_MODAL: {
        return {...state, showModal: action.payload};
    }
    default:
      return state;
  }
}
