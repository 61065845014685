import { USER_LIST,USER_DROPDOWN,USER_LOADER } from '../../constants/ActionTypes'

const INIT_STATE = {
  list:[],
  dropdown:[],
  statusLoader:false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_LIST: {
      return {...state, list: action.payload};
    }
    case USER_DROPDOWN: {
      return {...state, dropdown: action.payload};
    }
    case USER_LOADER: {
      return {...state, statusLoader: action.payload};
    }

    default:
      return state;
  }
}
