// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';

/*New Project Routes*/
export const USER_LIST = 'USER_LIST'
export const USER_DROPDOWN = 'USER_DROPDOWN'
export const CALL_LIST = 'CALL_LIST'
export const CREATE_USER = 'CREATE_USER'
export const CLIENT_FORM_LOADER = 'CLIENT_FORM_LOADER'
export const ROLE_LIST = 'ROLE_LIST'
export const ROLE_DROPDOWN = 'ROLE_DROPDOWN'
export const CALL_DETAILS = 'CALL_DETAILS'
export const DASHBOARD_RECORDS = 'DASHBOARD_RECORDS'
export const DRAWER_VISIBILITY = 'DRAWER_VISIBILITY'
export const VALIDATION_ERR = 'VALIDATION_ERR'
export const TWILIO_NUMBERS_LIST = 'TWILIO_NUMBERS_LIST'
export const TWILIO_LOADER = 'TWILIO_LOADER'
export const SHOW_TWILIO_NUMBERS_LIST_MODAL = 'SHOW_TWILIO_NUMBERS_LIST_MODAL'
export const USER_LOADER = 'USER_LOADER'