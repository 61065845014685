import { ROLE_LIST,ROLE_DROPDOWN } from '../../constants/ActionTypes'

const INIT_STATE = {
  list:[],
  dropdown:[],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ROLE_LIST: {
      return {...state, list: action.payload};
    }
    case ROLE_DROPDOWN: {
      return {...state, dropdown: action.payload};
    }
    default:
      return state;
  }
}
