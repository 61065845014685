import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  CLIENT_FORM_LOADER,
  DRAWER_VISIBILITY,
  VALIDATION_ERR
} from '../../constants/ActionTypes'

const INIT_STATE = {
  error: "",
  loading: false,
  message: '',
  affiliateGroups:[],
  createClientLoader:false,
  drawerLoader:false,
  drawerVisibility:false,
  validationErr:false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return {...state, error: '', message: '', loading: true};
    }
    case FETCH_SUCCESS: {
      return {...state, error: '', message: '', loading: false};
    }
    case SHOW_MESSAGE: {
      return {...state, error: '', message: action.payload, loading: false};
    }
    case FETCH_ERROR: {
      return {...state, loading: false, error: action.payload, message: ''};
    }
    case HIDE_MESSAGE: {
      return {...state, loading: false, error: '', message: ''};
    }
    case CLIENT_FORM_LOADER: {
      return {...state, createClientLoader: action.payload, drawerLoader: action.payload};
    }
    case DRAWER_VISIBILITY: {
      return {...state, drawerVisibility: action.payload,createClientLoader: false};
    }
    case VALIDATION_ERR: {
      return {...state, validationErr: action.payload,createClientLoader: false};
    }
    default:
      return state;
  }
}
