import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./Dashboard'))}/>
      <Route path={`${match.url}clients`} component={asyncComponent(() => import('./Client'))}/>
      <Route path={`${match.url}admins`} component={asyncComponent(() => import('./Settings'))}/>
      <Route path={`${match.url}calls`} component={asyncComponent(() => import('./Calls'))}/>

    </Switch>
  </div>
);

export default App;
